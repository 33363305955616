.loginboxnew-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.loginboxnew-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  width: 100%;
  height: 100%;
}

.loginboxnew-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  height: 100%;
  position: relative;
}

.loginboxnew-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
  background-color: rgb(255, 255, 255);
}

.loginboxnew-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding: 0px 11px;
}

.loginboxnew-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 31px;
  text-align: center;
  padding: 10px 0px !important;
}

.loginboxnew-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 200px;
  height: 60px;
  margin-top: 41px;
}

.loginboxnew-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.loginboxnew-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.loginboxnew-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  right: 15px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 200px;
  z-index: 99;
  text-align: center;
  background: rgb(255, 255, 255);
}

.loginboxnew-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
}

.loginboxnew-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.loginboxnew-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.loginboxnew-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.loginboxnew-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 13px;
}

.loginboxnew-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 13px;
}

.loginboxnew-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  margin-top: 8px;
  height: 41px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(243, 243, 243);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 10px;
}

.loginboxnew-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
  width: 100%;
}

.loginboxnew-19 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.loginboxnew-20 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  font-size: 13px;
  color: silver;
  display: none;
}

.loginboxnew-21 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.loginboxnew-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  margin-bottom: 13px;
}

.loginboxnew-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 13px;
}

.loginboxnew-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  margin-top: 8px;
  height: 41px;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  border: 1px solid rgb(243, 243, 243);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 10px;
}

.loginboxnew-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 16px;
  line-height: 1.4em;
  height: 1.4em;
  min-height: 1.4em;
  overflow: hidden;
  width: 100%;
}

.loginboxnew-26 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
}

.loginboxnew-27 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: absolute;
  left: 0px;
  overflow: hidden;
  text-overflow: clip;
  white-space: pre;
  word-break: keep-all;
  pointer-events: none;
  line-height: inherit;
  top: auto !important;
  font-size: 13px;
  color: silver;
  display: none;
}

.loginboxnew-28 {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  border: none;
  padding: 0px;
  margin: 0px;
  text-decoration: inherit;
  width: 100%;
  position: relative;
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  font: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
}

.loginboxnew-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  color: var(--boutton-background-color) !important;
  text-align: right;
}

.loginboxnew-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
}

.loginboxnew-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  -webkit-box-pack: start;
  justify-content: flex-start;
  line-height: normal;
  font-size: 12px;
  color: rgb(255, 51, 51);
  margin-bottom: 20px;
  min-height: 15px;
}

.loginboxnew-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.loginboxnew-33 {
  margin: 0px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  border: 0px;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  padding: 0px 20px;
  z-index: 1;
  box-sizing: border-box;
  transition: all 0.15s ease 0s;
  border-color: rgb(192, 196, 204);
  line-height: 41px;
  border-radius: 4px;
  font-size: 15px;
  height: 41px !important;
  background-color: var(--boutton-background-color);
  color: rgb(255, 255, 255) !important;
  overflow: visible;
  margin-bottom: 5px;
}

.loginboxnew-33:after {
  content: " ";
  position: absolute;
  pointer-events: none;
  box-sizing: border-box;
  transform-origin: 0px 0px;
  left: 0px;
  top: 0px;
  width: 199.8%;
  transform: scale(0.5);
  z-index: 1;
  border: none !important;
}

.loginboxnew-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding: 15px 0px;
  margin-top: 15px;
  color: var(--boutton-background-color) 
}

.loginboxnew-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  color: var(--boutton-background-color) !important;
  font-size: 13px;
}

.loginboxnew-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
}

.loginboxnew-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  position: fixed;
  bottom: 31px;
  width: 100%;
}

.loginboxnew-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.loginboxnew-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
}

.loginboxnew-40 {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin: 0px 7px;
}

.loginboxnew-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.loginboxnew-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  display: block;
  cursor: pointer;
}

.loginboxnew-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  z-index: 998;
  position: fixed;
  left: 0px;
  right: 0px;
  display: none;
}

.loginboxnew-44 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  z-index: 998;
  box-sizing: border-box;
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-bottom: env(safe-area-inset-bottom);
  background-color: rgb(255, 255, 255);
  backdrop-filter: none;
}

.loginboxnew-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1px;
  transform: scaleY(0.5);
  background-color: rgba(0, 0, 0, 0.33);
}

.loginboxnew-46 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.loginboxnew-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.loginboxnew-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.loginboxnew-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.loginboxnew-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: var(--boutton-background-color);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.loginboxnew-51 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.loginboxnew-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.loginboxnew-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.loginboxnew-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.loginboxnew-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.loginboxnew-56 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.loginboxnew-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.loginboxnew-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.loginboxnew-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.loginboxnew-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.loginboxnew-61 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.loginboxnew-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.loginboxnew-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.loginboxnew-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.loginboxnew-65 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.loginboxnew-66 {
  margin: 0px;
  box-sizing: border-box;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  font-size: 0px;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.loginboxnew-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: relative;
  flex-direction: column;
  cursor: pointer;
  height: 50px;
}

.loginboxnew-68 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  margin-top: 5px;
  width: 26px;
  height: 26px;
}

.loginboxnew-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.loginboxnew-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  color: rgb(153, 153, 153);
  font-size: 10px;
  line-height: normal;
  margin-top: 0px;
}

.loginboxnew-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  margin-bottom: env(safe-area-inset-bottom);
  height: 50px;
}

.loginboxnew-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
}

.loginboxnew-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  z-index: 999;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

@media screen and (min-width: 500px) and (min-height: 500px) {
  .loginboxnew-73 {
    background: none;
  }
}

.loginboxnew-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  left: 6px;
  right: 6px;
  bottom: 6px;
  transform: translateY(100%);
  backface-visibility: hidden;
  z-index: 999;
  visibility: hidden;
  transition: transform 0.3s ease 0s, visibility 0.3s ease 0s,
    -webkit-transform 0.3s ease 0s;
}

@media screen and (min-width: 500px) and (min-height: 500px) {
  .loginboxnew-74 {
    width: 300px;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    
    transition: opacity 0.3s ease 0s, visibility 0.3s ease 0s;
  }
}

.loginboxnew-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(252, 252, 253);
}

@media screen and (min-width: 500px) and (min-height: 500px) {
  .loginboxnew-75 {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px 5px;
  }
}

.loginboxnew-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  max-height: 260px;
  overflow: hidden;
}

.loginboxnew-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  transform: translateY(0px) translateZ(0px);
}

.loginboxnew-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: rgb(252, 252, 253);
  margin-top: 6px;
}

@media screen and (min-width: 500px) and (min-height: 500px) {
  .loginboxnew-78 {
    display: none;
  }
}

.loginboxnew-79 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  padding: 10px 6px;
  text-align: center;
  font-size: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: rgb(0, 0, 0);
}

.loginboxnew-79:before {
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 1px;
  border-top: 1px solid rgb(229, 229, 229);
  transform-origin: 0px 0px;
  transform: scaleY(0.5);
  display: none;
}

.loginboxnew-79:active {
  background-color: rgb(236, 236, 236);
}

.loginboxnew-80 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.loginboxnew-81 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  inset: 0px;
  z-index: 999;
  box-sizing: border-box;
  display: none;
}

.loginboxnew-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  z-index: 999;
  inset: 0px;
  background: rgba(0, 0, 0, 0.5);
}

.loginboxnew-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  z-index: 999;
  width: 80%;
  max-width: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
}

.loginboxnew-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
  padding: 1.3em 1.6em;
  min-height: 40px;
  font-size: 15px;
  line-height: 1.4;
  color: rgb(153, 153, 153);
  max-height: 400px;
  overflow: hidden auto;
}

.loginboxnew-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  position: relative;
  line-height: 48px;
  font-size: 18px;
  display: flex;
}

.loginboxnew-85:after {
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 1px;
  border-top: 1px solid rgb(213, 213, 214);
  color: rgb(213, 213, 214);
  transform-origin: 0px 0px;
  transform: scaleY(0.5);
}

.loginboxnew-86 {
  margin: 0px;
  box-sizing: border-box;
  display: block;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  cursor: pointer;
  color: rgb(0, 0, 0);
}

.loginboxnew-86:after {
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1px;
  bottom: 0px;
  border-left: 1px solid rgb(213, 213, 214);
  transform-origin: 0px 0px;
  transform: scaleX(0.5);
  display: none;
}

.loginboxnew-86:active {
  background-color: rgb(238, 238, 238);
}

.loginboxnew-87 {
  margin: 0px;
  box-sizing: border-box;
  display: block;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  cursor: pointer;
  color: var(--boutton-background-color);
}

.loginboxnew-87:active {
  background-color: rgb(238, 238, 238);
}

.loginboxnew-87:after {
  content: " ";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 1px;
  bottom: 0px;
  border-left: 1px solid rgb(213, 213, 214);
  transform-origin: 0px 0px;
  transform: scaleX(0.5);
}