.c2ccell-1:focus {
  outline: 0px;
}

.c2ccell-2 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #323233;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
  font-style: normal;
  font-weight: 400;
}

.c2ccell-2:focus {
  outline: 0px;
}

.c2ccell-2:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2ccell-3 {
  flex: 1 1 0%;
}

.c2ccell-3:focus {
  outline: 0px;
}

.c2ccell-4 {
  font-size: 4vw;
  line-height: 5.06667vw;
  color: rgb(77, 82, 96);
}

.c2ccell-5 {
  margin-top: 0;
  color: #969799;
  font-size: 3.2vw;
  line-height: 4.8vw;
}

.c2ccell-5:focus {
  outline: 0px;
}

.c2ccell-6 {
  font-size: 3.2vw;
  line-height: 4.53333vw;
  color: rgb(191, 191, 191);
}

.c2ccell-7 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #969799;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2ccell-7:focus {
  outline: 0px;
}

.c2ccell-8 {
  font-weight: 500;
  font-size: 3.73333vw;
  line-height: 5.33333vw;
  color: rgb(77, 82, 96);
}

.c2ccell-9 {
  font-size: 3.2vw;
  line-height: 4.53333vw;
  color: rgb(191, 191, 191);
}

.c2ccell-10 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #323233;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
  font-style: normal;
  font-weight: 400;
}

.c2ccell-10:focus {
  outline: 0px;
}

.c2ccell-10:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2ccell-11 {
  flex: 1 1 0%;
}

.c2ccell-11:focus {
  outline: 0px;
}

.c2ccell-13 {
  margin-top: 0;
  color: #969799;
  font-size: 3.2vw;
  line-height: 4.8vw;
}

.c2ccell-13:focus {
  outline: 0px;
}

.c2ccell-14 {
  font-size: 3.2vw;
  line-height: 4.53333vw;
  color: rgb(191, 191, 191);
}

.c2ccell-15 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #969799;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2ccell-15:focus {
  outline: 0px;
}

.c2ccell-16 {
  font-weight: 500;
  font-size: 3.73333vw;
  line-height: 5.33333vw;
  color: rgb(231, 75, 113);
}

.c2ccell-17 {
  font-size: 3.2vw;
  line-height: 4.53333vw;
  color: rgb(191, 191, 191);
}

.c2ccell-18 {
  color: #969799;
  font-size: 3.7333333vw;
  line-height: 13.3333333vw;
  text-align: center;
}

.c2ccell-18:focus {
  outline: 0px;
}

.c2ccell-19 {
  height: 0px;
  pointer-events: none;
}

.c2ccell-19:focus {
  outline: 0px;
}
