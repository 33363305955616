.extractlist-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  color: rgb(48, 49, 51);
  font-size: 14px;
}

.extractlist-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
}

.extractlist-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 390px;
  height: 0px;
}

.extractlist-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  padding: 14px 11px 35px 10px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.extractlist-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-8 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-16:before {
  content: "";
}

.extractlist-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-20 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-28 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-28:before {
  content: "";
}

.extractlist-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-32 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-40:before {
  content: "";
}

.extractlist-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-43 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-44 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-52:before {
  content: "";
}

.extractlist-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-54 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-55 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-56 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-57 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-58 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-59 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-60 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-61 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-62 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-63 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-64 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-64:before {
  content: "";
}

.extractlist-65 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-66 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-67 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-68 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-69 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-70 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-71 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-72 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-73 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-74 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-75 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-76 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-76:before {
  content: "";
}

.extractlist-77 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  box-shadow: rgba(3, 3, 3, 0.05) 0px 2px 5px 0px;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  width: 173px;
  margin-bottom: 10px;
}

.extractlist-78 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.extractlist-79 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(51, 51, 51);
  font-size: 16px;
  padding: 14px 10px 12px;
  border-bottom: 0.5px solid rgb(241, 243, 244);
}

.extractlist-80 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0px 10px 0px 0px;
  width: 26px;
  height: 26px;
}

.extractlist-81 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-position: 0% 0%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.extractlist-82 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  
}

.extractlist-83 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-weight: 600;
}

.extractlist-84 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-85 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  padding: 8px 15px 9px 0px;
  color: rgb(28, 159, 255);
  text-align: right;
}

.extractlist-86 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-right: 2px;
}

.extractlist-87 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.extractlist-88 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
}

.extractlist-88:before {
  content: "";
}

.extractlist-89 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}
