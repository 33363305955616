.hometopbar-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  top: 0px;
  margin-top: 0px;
  height: 52px;
  box-sizing: border-box;
  background-color: rgb(248, 248, 248);
  z-index: 9999;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: sticky; /* 添加sticky定位 */
  top: 0; /* 设置顶部距离 */
  width: 100%; /* 确保宽度充满 */
}

.hometopbar-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hometopbar-3 {
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-align: center;
  min-height: 40px;
  margin: 0px;
  place-self: center;
  width: 126px;
}

.hometopbar-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.hometopbar-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  max-width: 100%;
  width: 100%;
  height: 100%;
  user-select: none;
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
}

.hometopbar-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.hometopbar-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.hometopbar-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.hometopbar-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.hometopbar-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.hometopbar-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  position: absolute;
  right: 15px;
  display: flex;
}
