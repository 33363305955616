.registercenter-1 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    position: relative;
    height: calc(100% - 44px - env(safe-area-inset-top));
}

.registercenter-2 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    color: rgb(48, 49, 51);
    font-size: 14px;
}

.registercenter-3 {
    -webkit-tap-highlight-color: transparent;
    width: 80%;
    margin: 0px auto;
    height: 800px;
}

.registercenter-4 {
    -webkit-tap-highlight-color: transparent;
    text-align: center;
    margin: 24px auto 10px;
}

.registercenter-5 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    position: relative;
    margin-bottom: 10px;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.registercenter-6 {
    margin: 0px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: rgb(0, 0, 0);
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    width: 106px;
    height: 32px;
    line-height: 32px;
    background: var(--boutton-background-color);
}

.registercenter-6:after {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: scale(0.5);
    transform-origin: 0px 0px;
    box-sizing: border-box;
    border-radius: 10px;
}

.registercenter-7 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.registercenter-8 {
    margin: 0px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    color: rgb(0, 0, 0);
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    width: 106px;
    height: 32px;
    line-height: 32px;
    background: rgb(213, 213, 213);
}

.registercenter-8:after {
    content: " ";
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: scale(0.5);
    transform-origin: 0px 0px;
    box-sizing: border-box;
    border-radius: 10px;
}

.registercenter-9 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    font-size: 13px;
    color: rgb(255, 255, 255);
}

.registercenter-10 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: fixed;
    right: 15px;
    bottom: 50px;
    width: 50px;
    height: 50px;
    border-radius: 200px;
    z-index: 99;
    text-align: center;
    background: rgb(255, 255, 255);
}

.registercenter-11 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
}

.registercenter-12 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    background-position: 0% 0%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.registercenter-13 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    user-select: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;

}

.registercenter-14 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
}

.registercenter-15 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.registercenter-16 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    line-height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.registercenter-17 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_phone.png) 12px center / 25px no-repeat;
}

.registercenter-17-1 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_email.png) 12px center / 25px no-repeat;
}

.registercenter-18 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.registercenter-19 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
}

.registercenter-20 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.registercenter-21 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    line-height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.registercenter-22 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_validatecode.png) 12px center / 25px no-repeat;
}

.registercenter-23 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.registercenter-24 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
}

.registercenter-25 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.registercenter-26 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    font-size: 12px;
    line-height: inherit;
    box-sizing: border-box;
    display: inline-block;
    margin-left: 10px;
}

.registercenter-27 {
    margin: 0px;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    position: relative;
    width: 120px;
    height: 45px;
}

.registercenter-28 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.registercenter-29 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.registercenter-30 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    inset: 0px;
    overflow: hidden;
    display: block;
    z-index: -1;
    visibility: hidden;
    animation: 1ms ease 0s 1 normal none running once-show;
}

.registercenter-31 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    inset: 0px;
    overflow: hidden;
}

.registercenter-32 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100000px;
    height: 100000px;
}

.registercenter-33 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    inset: 0px;
    overflow: hidden;
}

.registercenter-34 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 200%;
    height: 200%;
}

.registercenter-35 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    line-height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.registercenter-36 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_password.png) 12px center / 25px no-repeat;
}

.registercenter-37 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.registercenter-38 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
}

.registercenter-39 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.registercenter-40 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    z-index: 10;
}

.registercenter-41 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    background-image: url(/public/img/login_btn_eye_close.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.registercenter-42 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    user-select: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;

}

.registercenter-43 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    line-height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.registercenter-44 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_password.png) 12px center / 25px no-repeat;
}

.registercenter-45 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.registercenter-46 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
}

.registercenter-47 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.registercenter-48 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    display: inline-block;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 5px;
    z-index: 10;
}

.registercenter-49 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    background-image: url(/public/img/login_btn_eye_close.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
}

.registercenter-50 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
    height: 100%;
    user-select: none;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;

}

.registercenter-51 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    line-height: 50px;
    margin: 10px auto;
    display: flex;
    justify-content: space-evenly;
    -webkit-box-align: center;
    align-items: center;
    font-size: 12px;
    position: relative;
}

.registercenter-52 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-height: 1.4em;
    overflow: hidden;
    width: 100%;
    color: rgb(0, 0, 0);
    padding-left: 50px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    border: 0.5px solid rgb(205, 205, 205);
    border-radius: 2px !important;
    background-color: rgb(255, 255, 255) !important;
    background: url(/public/img/login_ico_yqm.png) 12px center / 25px no-repeat;
}

.registercenter-53 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
}

.registercenter-54 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: absolute;
    left: 0px;
    color: grey;
    overflow: hidden;
    text-overflow: clip;
    white-space: pre;
    word-break: keep-all;
    pointer-events: none;
    line-height: inherit;
    top: auto !important;
}

.registercenter-55 {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: inherit;
    width: 100%;
    position: relative;
    display: block;
    height: 100%;
    background: none;
    color: inherit;
    opacity: 1;
    font: inherit;
    letter-spacing: inherit;
    text-align: inherit;
    text-indent: inherit;
    text-transform: inherit;
    text-shadow: inherit;
}

.registercenter-56 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    padding: 0px;
    margin: 10px auto;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    -webkit-box-pack: start;
    justify-content: flex-start;
    line-height: normal;
    font-size: 12px;
    color: rgb(255, 51, 51);
    margin-bottom: 20px;
}

.registercenter-57 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.registercenter-58 {
    margin: 0px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 5px;
    background-color: var(--boutton-background-color);
    font-size: 11px;
    color: rgb(255, 255, 255);
    height: 43px;
    line-height: 43px;
}

.registercenter-58:after {
    content: " ";
    width: 200%;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: scale(0.5);
    transform-origin: 0px 0px;
    box-sizing: border-box;
    border-radius: 10px;
}

.registercenter-59 {
    margin: 0px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 5px;
    font-size: 11px;
    color: var(--boutton-background-color);
    height: 30px;
    line-height: 30px;
    border: 1px solid rgba(0, 0, 0, .2);
    background-color: #f8f8f8;
}

.registercenter-60 {
    margin: 0px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    -webkit-tap-highlight-color: transparent;
    overflow: hidden;
    background-color: rgb(248, 248, 248);
    cursor: pointer;
    height: 27px;
    line-height: 27px;
    font-size: 12px;
    color: var(--boutton-background-color);
}

.registercenter-60:after {
    content: " ";
    width: 200%;
    position: absolute;
    top: 0px;
    left: 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transform: scale(0.5);
    transform-origin: 0px 0px;
    box-sizing: border-box;
    border-radius: 10px;
}

.registercenter-61 {
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
}

.registercenter-62 {
    -webkit-tap-highlight-color: transparent;
    list-style-type: none;
    margin: 0px;
    padding: 0px;
}

.register-34 {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    color: white;
    font: inherit;
    margin: 0px;
    overflow: visible;
    text-transform: none;
    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    padding: 0px 8px;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.2s ease 0s;
    appearance: none;
    background-color: var(--boutton-background-color);
    border: 1px solid rgb(235, 237, 240);
}

.register-34:focus {
    outline: 0px;
}

.register-34:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    border-style: inherit;
    border-width: inherit;
    border-image: inherit;
    border-color: rgb(0, 0, 0);
    border-radius: inherit;
    transform: translate(-50%, -50%);
    opacity: 0;
    content: " ";
}


.registerPhone-25 {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    color: white;
    font: inherit;
    margin: 0px;
    overflow: visible;
    text-transform: none;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 0px 8px;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    transition: opacity 0.2s ease 0s;
    appearance: none;
    background-color: var(--boutton-background-color);
    border: 1px solid rgb(235, 237, 240);
}

.registerPhone-25:focus {
    outline: 0px;
}

.registerPhone-25:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    border-style: inherit;
    border-width: inherit;
    border-image: inherit;
    border-color: rgb(0, 0, 0);
    border-radius: inherit;
    transform: translate(-50%, -50%);
    opacity: 0;
    content: " ";
}

.registerPhone-26:focus {
    outline: 0px;
}

.registerPhone-26 {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
    height: 100%;
}

.registerPhone-26:before {
    content: " ";
}

.registerPhone-27:focus {
    outline: 0px;
}

.registerPhone-27 {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    margin: 0px;
    padding: 0px;
    border: 0px;
    outline: 0px;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}