.centerDiv {
  box-sizing: border-box;
  padding: 10px 10px;
}

.imageDiv {
  box-sizing: border-box;
}

.image {
  border-radius: 10px;
}

.centerFontDiv {
  margin-top: 15px;
  padding: 0;
}

.centerItem {
  position: relative;
  padding-top: 10px;
  min-height: 80px;
  padding-bottom: 10px;
  border-bottom: solid 1px #E5E5E5;
  display: flex;

}

.logo-img {
  width: 80px;
  height: 80px;
  border-radius: 5px;
}

.logo-font {
  margin-left: 10px;
  width: 47vw;
  min-height: 80px;
}

.lang {
  font-size: 15px;
  color: #666;
}

.buttonD {
  position: absolute;
  right: 0;
  margin-top: 22px;
  height: 40px;
  background-color: var(--boutton-background-color);
  color: white;
  font-size: 16px;
}

.fontDiv {
  margin-top: 20px;
  font-size: 11px;
}

.fontInfoDiv {
  margin-top: 20px;
  font-size: 17px;
  font-family: '微软雅黑';
}