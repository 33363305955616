.popover-box-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

.popover-box-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  text-align: center;
  color: rgb(0, 0, 0);
  line-height: 50px;
  border-bottom: 1px solid rgb(230, 234, 237);
  position: relative;
  border: 0px;
}

.popover-box-3 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  font-weight: 700;
  font-size: 14px;
  text-align: center !important;
  margin-left: 11px;
}

.popover-box-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  font-family: FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  width: 50px;
  text-align: center;
  line-height: 50px;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 23px;
  margin-right: 0px;
  color: rgb(0, 0, 0);
}

.popover-box-4:before {
  content: "";
}

.popover-box-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  padding: 10px;
}

.popover-box-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 264px;
  padding: 10px 0px 30px;
  text-align: center;
  color: var(--boutton-background-color);
}

.daojishi-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0.1667rem solid rgb(217, 0, 18);
  outline: 0px;
  font-size: 2rem;
  vertical-align: baseline;
  background: transparent;
  color: rgb(217, 0, 18);
  line-height: 20px;
  margin-top: 0.53333rem;
  width: 8rem;
  height: 8rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4rem;
}

.popover-box-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
  width: 165px;
  height: 165px;
  text-align: center;
}

.popover-box-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.popover-box-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.popover-box-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
  display: block;
  z-index: -1;
  visibility: hidden;
  animation: 1ms ease 0s 1 normal none running once-show;
}

.popover-box-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.popover-box-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100000px;
  height: 100000px;
}

.popover-box-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.popover-box-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 200%;
  height: 200%;
}

.popover-box-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(248, 248, 251);
  font-size: 13px;
  line-height: 1.8;
  padding: 1px;
}

.popover-box-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(141, 157, 188);
}

.popover-box-17 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  text-align: right;
}

.popover-box-19 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  background: rgb(248, 248, 251);
  font-size: 13px;
  line-height: 1.8;
  padding: 1px;
}

.popover-box-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  color: rgb(141, 157, 188);
}

.popover-box-22 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-23 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-24 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-25 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 173, 144);
}

.popover-box-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  text-align: right;
}

.popover-box-27 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(205, 78, 101);
}

.popover-box-28 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-29 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
}

.popover-box-30 {
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  color: rgb(28, 173, 144);
}

.popover-box-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  text-align: center;
  width: 100%;
  color: var(--boutton-background-color);
  font-size: 14px;
}

.popover-box-32 {
  margin: 0px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 14px;
  padding-right: 14px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  overflow: hidden;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  padding: 0px 10px;
  background: var(--boutton-background-color);
  color: rgb(255, 255, 255);
  border: none;
  font-size: 14px;
  margin-top: 38px;
  width: 100%;
  border-radius: 2px;
  background-color: var(--boutton-background-color);
}

.popover-box-32:after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  transform: scale(0.5);
  transform-origin: 0px 0px;
  box-sizing: border-box;
}