.c2cckimage-2 {
  padding: 3.2vw 4.26667vw;
  background-color: rgb(255, 255, 255);
}

.c2cckimage-3 {
  background: rgb(243, 245, 248);
  padding: 2.13333vw;
  border-radius: 1.06667vw;
  font-weight: 600;
  font-size: 3.2vw;
  color: rgb(231, 75, 113);
}

.c2cckimage-5 {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.c2cckimage-9 {
  margin: 3.2vw 0px;
}

.c2cckimage-10 {
  background: transparent;
  margin: 0 4.2666667vw;
  border-radius: 2.1333333vw;
  overflow: hidden;
}

.c2cckimage-10:focus {
  outline: 0px;
}

.c2cckimage-11 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-11:focus {
  outline: 0px;
}

.c2cckimage-11:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-12 {
  flex: 1 1 0%;
}

.c2cckimage-12:focus {
  outline: 0px;
}

.c2cckimage-14 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-14:focus {
  outline: 0px;
}

.c2cckimage-16 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-17 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-17:focus {
  outline: 0px;
}

.c2cckimage-17:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-18 {
  flex: 1 1 0%;
}

.c2cckimage-18:focus {
  outline: 0px;
}

.c2cckimage-20 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-20:focus {
  outline: 0px;
}

.c2cckimage-22 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-23 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-23:focus {
  outline: 0px;
}

.c2cckimage-23:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-24 {
  flex: 1 1 0%;
}

.c2cckimage-24:focus {
  outline: 0px;
}

.c2cckimage-26 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-26:focus {
  outline: 0px;
}

.c2cckimage-28 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-29 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-29:focus {
  outline: 0px;
}

.c2cckimage-29:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-30 {
  flex: 1 1 0%;
}

.c2cckimage-30:focus {
  outline: 0px;
}

.c2cckimage-32 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-32:focus {
  outline: 0px;
}

.c2cckimage-34 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-35 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-35:focus {
  outline: 0px;
}

.c2cckimage-35:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-36 {
  flex: 1 1 0%;
}

.c2cckimage-36:focus {
  outline: 0px;
}

.c2cckimage-38 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-38:focus {
  outline: 0px;
}

.c2cckimage-40 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-41 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-41:focus {
  outline: 0px;
}

.c2cckimage-41:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-42 {
  flex: 1 1 0%;
}

.c2cckimage-42:focus {
  outline: 0px;
}

.c2cckimage-44 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-44:focus {
  outline: 0px;
}

.c2cckimage-46 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-47 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-47:focus {
  outline: 0px;
}

.c2cckimage-47:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-48 {
  flex: 1 1 0%;
}

.c2cckimage-48:focus {
  outline: 0px;
}

.c2cckimage-50 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-50:focus {
  outline: 0px;
}

.c2cckimage-52 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-53 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-53:focus {
  outline: 0px;
}

.c2cckimage-53:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
}

.c2cckimage-54 {
  flex: 1 1 0%;
}

.c2cckimage-54:focus {
  outline: 0px;
}

.c2cckimage-56 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-56:focus {
  outline: 0px;
}

.c2cckimage-58 {
  width: 4.26667vw;
  height: 4.26667vw;
  margin-left: 1.06667vw;
}

.c2cckimage-59 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-59:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
  display: none;
}

.c2cckimage-59:focus {
  outline: 0px;
}

.c2cckimage-60 {
  flex: 1 1 0%;
}

.c2cckimage-60:focus {
  outline: 0px;
}

.c2cckimage-62 {
  flex: 1 1 0%;
  position: relative;
  overflow: hidden;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
}

.c2cckimage-62:focus {
  outline: 0px;
}

.c2cckimage-64 {
  padding: 4.26667vw;
  border-radius: 3.2vw 3.2vw 0px 0px;
  background-color: rgb(255, 255, 255);
}

.c2cckimage-65 {
  margin: 0px;
}

.c2cckimage-65:focus {
  outline: 0px;
}

.c2cckimage-66 {
  background: transparent;
}

.c2cckimage-66:focus {
  outline: 0px;
}

.c2cckimage-67 {
  position: relative;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 2.6666667vw 4.2666667vw;
  overflow: hidden;
  color: #9097a7;
  font-size: 3.7333333vw;
  line-height: 6.4vw;
  background: #fff;
  align-items: center;
}

.c2cckimage-67:after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 4.2666667vw;
  bottom: 0px;
  left: 4.2666667vw;
  border-bottom: 1px solid #f5f5f5;
  transform: scaleY(0.5);
  display: none;
}

.c2cckimage-67:focus {
  outline: 0px;
}

.c2cckimage-68 {
  flex: 0 0 auto;
  box-sizing: border-box;
  width: auto;
  margin-right: 3.2vw;
  color: #b9c5db;
  text-align: left;
  overflow-wrap: break-word;
}

.c2cckimage-68:focus {
  outline: 0px;
}

.c2cckimage-70 {
  flex: 1 1 0%;
  position: relative;
  color: #4d5260;
  text-align: right;
  vertical-align: middle;
  overflow-wrap: break-word;
  overflow: visible;
}

.c2cckimage-70:focus {
  outline: 0px;
}

.c2cckimage-71 {
  display: flex;
  align-items: center;
}

.c2cckimage-71:focus {
  outline: 0px;
}

.c2cckimage-72 {
  box-sizing: border-box;
  width: 100%;
  min-width: 0px;
  margin: 0px;
  padding: 0px;
  color: #f0b90b;
  line-height: inherit;
  text-align: left;
  background-color: transparent;
  border: 0px;
  resize: none;
  user-select: auto;
  display: flex;
  align-items: center;
  min-height: 6.4vw;
  cursor: default;
}

.c2cckimage-72:focus {
  outline: 0px;
}

.c2cckimage-73 {
  position: relative;
  display: inline-block;
}

.c2cckimage-73:focus {
  outline: 0px;
}

.c2cckimage-74 {
  display: flex;
  flex-wrap: wrap;
}

.c2cckimage-74:focus {
  outline: 0px;
}

.c2cckimage-75 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 21.3333333vw;
  height: 21.3333333vw;
  margin: 5px 2.1333333vw 2.1333333vw 0;
  padding-top: 10px;
  background: #f7f8fa;
}

.c2cckimage-75:active {
  background-color: #f2f3f5;
}

.c2cckimage-75:focus {
  outline: 0px;
}

.c2cckimage-76 {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: vant-icon;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 5.33333vw;
  color: #858a8f;
}

.c2cckimage-76:focus {
  outline: 0px;
}

.c2cckimage-76:before {
  display: inline-block;
  content: "";
}

.c2cckimage-77 {
  margin-top: 2.1333333vw;
  color: #969799;
  font-size: 3.2vw;
}

.c2cckimage-77:focus {
  outline: 0px;
}

.c2cckimage-78 {
  color: inherit;
  font: inherit;
  font-size: 100%;
  font-family: inherit;
  box-sizing: border-box;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  opacity: 0;
}

.c2cckimage-78:focus {
  outline: 0px;
}

.c2cckimage-80 {
  font: inherit;
  font-synthesis: none;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  position: relative;
  box-sizing: border-box;
  height: 11.7333333vw;
  margin: 0px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  transition: opacity 0.2s;
  appearance: none;
  padding: 0 4vw;
  font-size: 3.7333333vw;
  display: block;
  width: 100%;
  color: white;
  background: var(--boutton-background-color);
  border: 0px;
}

.c2cckimage-80:focus {
  outline: 0px;
}

.c2cckimage-80:active {
  background-image: linear-gradient(0deg,
      rgba(0, 0, 0, 0.05),
      rgba(0, 0, 0, 0.05));
}

.c2cckimage-80:before {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  border-style: inherit;
  border-width: inherit;
  border-image: inherit;
  border-color: #000;
  border-radius: inherit;
  transform: translate(-50%, -50%);
  opacity: 0;
  content: " ";
}

.c2cckimage-81 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c2cckimage-81:focus {
  outline: 0px;
}

.c2cckimage-81:before {
  content: " ";
}

.c2cckimage-82:focus {
  outline: 0px;
}