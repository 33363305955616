.marketTopBar-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: calc(0px);
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 15px;
}

.marketTopBar-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  margin-right: 20px;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  text-align: center;
  width: 213px;
}

.marketTopBar-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px 0px;
  background-color: rgb(43, 49, 57);
  border-radius: 2px;
  color: #1b1d23;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  background-image: linear-gradient(45deg, var(--boutton-background-color), var(--boutton-background-color)),
    linear-gradient(rgb(43, 49, 57), rgb(43, 49, 57));
  background-blend-mode: normal, normal;
}

.marketTopBar-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px 0px;
  /* background-color: rgb(43, 49, 57); */
  border-radius: 2px;
  color: #1b1d23;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  background: #f5f5f5;
}

.marketTopBar-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 5px 0px;
  background-color: rgb(43, 49, 57);
  border-radius: 2px;
  color: #1b1d23;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}

.marketTopBar-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.marketTopBar-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  height: auto;
  width: auto;
  cursor: pointer;
  margin-right: 15px !important;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 12px;
  color: #1b1d23;
}

.marketTopBar-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  margin-right: 4px;
}

.marketTopBar-8:before {
  content: '';
}

.marketTopBar-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.marketTopBar-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.marketTopBar-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: uicon-iconfont;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: relative;
  font-size: 20px;
  font-weight: normal;
  top: 0px;
}

.marketTopBar-11-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: uicon-iconfont;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: relative;
  font-size: 20px;
  font-weight: normal;
  top: 0px;
}

.marketTopBar-11:before {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  content: '\e65f';
  /* color: white; */
  color: #1b1d23;
}

.marketTopBar-11-1:before {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  content: '\e669';
  color: rgb(255, 185, 0);
}

.marketTopBar-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}
