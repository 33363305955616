.hyjyjl-1 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  padding: 10px;
  width: 95%;
  margin: auto;
}

.hyjyjl-2 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;

  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 11px;
  box-sizing: border-box;
  margin-bottom: 11px;
}

.hyjyjl-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 5px;
}

.hyjyjl-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.hyjyjl-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(43, 49, 57);
  font-weight: 600;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  background: rgb(205, 78, 101) !important;
  width: 55px;
  height: 19px;
  border-radius: 9px;
  font-size: 11px;
  color: rgb(255, 255, 255);
  line-height: 19px;
  text-align: center;
  margin-left: 5px;
}

.hyjyjl-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(131, 131, 131);
}

.hyjyjl-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 11px;
}

.hyjyjl-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(43, 49, 57);
  font-weight: 600;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.hyjyjl-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-left: 3px;
  font-size: 14px;
}

.hyjyjl-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(131, 131, 131);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(205, 78, 101) !important;
  margin-left: 5px;
}

.hyjyjl-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-17 {
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  height: 0.5px;
  width: 100%;
  margin: 5px 0px;
  background-color: rgb(0, 0, 0);
  opacity: 0.08;
}

.hyjyjl-18 {
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  width: 90%;
  margin: 11px auto;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.hyjyjl-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-25 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-26 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-27 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-28 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 11px;
  box-sizing: border-box;
  margin-bottom: 11px;
}

.hyjyjl-29 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 5px;
}

.hyjyjl-30 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.hyjyjl-31 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(43, 49, 57);
  font-weight: 600;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-32 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  background: rgb(28, 173, 144) !important;
  width: 55px;
  height: 19px;
  border-radius: 9px;
  font-size: 11px;
  color: rgb(255, 255, 255);
  line-height: 19px;
  text-align: center;
  margin-left: 5px;
}

.hyjyjl-33 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(131, 131, 131);
}

.hyjyjl-34 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 11px;
}

.hyjyjl-35 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(43, 49, 57);
  font-weight: 600;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-36 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.hyjyjl-37 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-38 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  margin-left: 3px;
  font-size: 14px;
}

.hyjyjl-39 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-40 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  color: rgb(131, 131, 131);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.hyjyjl-41 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-size: 17px;
  color: rgb(28, 173, 144) !important;
  margin-left: 5px;
}

.hyjyjl-42 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.hyjyjl-43 {
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  height: 0.5px;
  width: 100%;
  margin: 5px 0px;
  background-color: rgb(0, 0, 0);
  opacity: 0.08;
}

.hyjyjl-44 {
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  width: 90%;
  margin: 11px auto;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.hyjyjl-45 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-46 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-47 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-48 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-49 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-50 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-51 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.hyjyjl-52 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.hyjyjl-53 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 11px;
  font-size: 14px;
  font-weight: 600;
}

.hyjyjl-54 {
  -webkit-tap-highlight-color: transparent;
  list-style-type: none;
  margin: 0px;

  background-color: rgb(255, 255, 255);
  border-radius: 5px;
  padding: 11px;
  box-sizing: border-box;
  margin-bottom: 11px;
}

.jyjl-99{
  text-align: center;
  height: 30px;
  font-size: 1.3em;
  color: #999999;
}