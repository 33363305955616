.lockupKJOrderList-1:focus {
  outline: 0px;
}

.lockupKJOrderList-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.lockupKJOrderList-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 18px 12px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  margin-top: 15px;
  border-radius: 5px;
}

.lockupKJOrderList-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockupKJOrderList-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 28px;
  height: 28px;
}

.lockupKJOrderList-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px 14px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 16px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 500;
  flex: 1 1 0%;
  text-align: left;
}

.lockupKJOrderList-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 12px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(119, 119, 119);
  font-weight: 400;
}

.lockupKJOrderList-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockupKJOrderList-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.lockupKJOrderList-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-11:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-11:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-12:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-12:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockupKJOrderList-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-14:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-14:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-15:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-15:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockupKJOrderList-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-17:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-17:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJOrderList-18:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJOrderList-18:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 600;
}

.lockupKJOrderList-19:focus {
  outline: 0px;
}

.lockupKJOrderList-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  height: 0px;
  pointer-events: none;
}
