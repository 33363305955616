.chatcenter-1 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.chatcenter-2 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.chatcenter-3 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 100%;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 980;
  background: rgb(255, 255, 255);
}

.chatcenter-3:after {
  content: " ";
  pointer-events: none;
  box-sizing: border-box;
  transform-origin: 0px 0px;
  height: 199.7%;
  transform: scale(0.5);
  border: 0px solid rgb(228, 231, 237);
  border-bottom-width: 1px;
}

.chatcenter-4 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 0px;
}

.chatcenter-5 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  position: relative;
  -webkit-box-align: center;
  align-items: center;
  height: 44px;
}

.chatcenter-6 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  flex: 0 1 0%;
  -webkit-box-flex: 0;
  padding: 7px 7px 7px 12px;
}

.chatcenter-7 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.chatcenter-8 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.chatcenter-9 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  display: flex;
  font-style: normal;
  font-variant: normal;
  font-kerning: auto;
  font-optical-sizing: auto;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-stretch: normal;
  line-height: 1;
  font-family: uicon-iconfont;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: relative;
  font-size: 22px;
  font-weight: normal;
  top: 0px;
  color: rgb(96, 98, 102);
}

.chatcenter-9:before {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  content: "";
}

.chatcenter-10 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.chatcenter-11 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  flex: 1 0 0%;
  position: absolute;
  height: 31px;
  text-align: center;
  left: 91px;
  right: 91px;
  width: 208px;
}

.chatcenter-12 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 31px;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  color: rgb(96, 98, 102);
  font-size: 16px;
  font-weight: normal;
}

.chatcenter-13 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.chatcenter-14 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.chatcenter-15 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
}

.chatcenter-16 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  height: 150px;
  background-color: var(--boutton-background-color);
  background-image: url(/public/img/1.b8af9232.png);
  position: absolute;
  background-size: 100% 100%;
  width: 100%;
  border-radius: 0px;
}

.chatcenter-17 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-size: 19px;
  color: rgb(255, 255, 255);
  font-weight: 700;
  position: absolute;
  top: 28px;
  left: 30px;
}

.chatcenter-18 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}

.chatcenter-19 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  margin-top: 150px;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.chatcenter-20 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
  margin-top: 166px;
}

.chatcenter-21 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  font-size: 12px;
  line-height: inherit;
  box-sizing: border-box;
}

.chatcenter-22 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  background-size: 100% 100%;
  border-radius: 16px;
  margin-top: 15px;
  padding: 20px 0px 19px 19px;
  background-image: url(/public/img/3.90d0ec1c.png);
}

.chatcenter-23 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  color: var(--boutton-background-color);
  font-weight: 700;
  font-size: 15px;
}

.chatcenter-24 {
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
}