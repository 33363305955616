.lokup-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px 16px 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-align: center;
}

.lokup-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  
  padding-top: 10px;
  border: 0px;
  outline: 0px;
  font-size: 1.12rem;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 800;
}

.lokup-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 26px 0px 27px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 12px;
  vertical-align: baseline;
  background: transparent;
  text-align: left;
  color: rgb(51, 51, 51);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.lokup-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 14px 19px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
}

.lokup-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(54, 54, 54);
  font-weight: 500;
  margin-bottom: 20px;
}

.lokup-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: var(--boutton-background-color);
}

.lokup-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}

.lokup-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.lokup-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(119, 119, 119);
  font-weight: 400;
}

.lokup-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.lokup-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(119, 119, 119);
  font-weight: 400;
}

.lokup-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-20 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 28px;
}

.lokup-21 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.lokup-22 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 36px;
  height: 36px;
  transform: translateY(-60px);
    filter: drop-shadow(var(--boutton-background-color) 0 60px);
}

.lokup-23 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  width: 84px;
  margin-top: 13px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-24 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-25 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.lokup-26 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 36px;
  height: 36px;
}

.lokup-27 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  width: 84px;
  margin-top: 13px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-28 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-29 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.lokup-30 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 36px;
  height: 36px;
}

.lokup-31 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  width: 84px;
  margin-top: 13px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-32 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lokup-33 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 14px 19px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
}

.lokup-34 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(54, 54, 54);
  font-weight: 500;
  margin-bottom: 20px;
}

.lokup-35 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  color: var(--boutton-background-color);
}

.lokup-36 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: start;
  text-align: left;
  margin-top: 12px;
}

.lokup-37 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(54, 54, 54);
  font-weight: 500;
  margin-bottom: 20px;
}

.lokup-38 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  width: 100%;
  margin-top: 5px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-39 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.lokup-40 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.lokup-41 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 14px 19px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
}

.lokup-42 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: start;
  text-align: left;
  margin-top: 12px;
}

.lokup-43 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 15px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(54, 54, 54);
  font-weight: 500;
  margin-bottom: 20px;
}

.lokup-44 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 11px;
  vertical-align: baseline;
  background: transparent;
  width: 100%;
  margin-top: 5px;
  color: rgb(51, 51, 51);
  font-weight: 400;
}

.lokup-45 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.lokup-46 {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.lokup-47 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  position: relative;
  display: inline-block;
  height: 44px;
  padding: 0px 15px;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;
  appearance: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(235, 237, 240);
  margin-top: 10px;
  width: 100%;
  background: var(--boutton-background-color);
  font-weight: 400;
}

.lokup-47:focus {
  outline: 0px;
}


.lokup-48:focus {
  outline: 0px;
}

.lokup-48 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.lokup-48:before {
  content: " ";
}

.lokup-49:focus {
  outline: 0px;
}

.lokup-49 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
