.lockupKJList-1:focus {
  outline: 0px;
}

.lockupKJList-1 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-2 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px 16px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: rgb(255, 255, 255);
  width: 100vw;
  display: flex;
  flex-direction: column;
}

.lockupKJList-3 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 18px 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-4 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockupKJList-5 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  border-style: none;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  width: 28px;
  height: 28px;
}

.lockupKJList-6 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px 14px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 16px;
  vertical-align: baseline;
  background: transparent;
  color: rgb(51, 51, 51);
  font-weight: 500;
  flex: 1 1 0%;
  text-align: left;
}

.lockupKJList-7 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  color: rgb(255, 255, 255);
  font: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 0px 8px;
  font-size: 12px;
  line-height: 1.2;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: opacity 0.2s ease 0s;
  appearance: none;
  background-color: rgb(7, 193, 96);
  border: none;
  background: var(--boutton-background-color);
}

.lockupKJList-7:focus {
  outline: 0px;
}


.lockupKJList-8:focus {
  outline: 0px;
}

.lockupKJList-8 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 100%;
}

.lockupKJList-8:before {
  content: " ";
}

.lockupKJList-9:focus {
  outline: 0px;
}

.lockupKJList-9 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-10 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  margin-top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.lockupKJList-11 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockupKJList-12 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-12:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-12:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-13 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-13:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-13:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-14 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockupKJList-15 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-15:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-15:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-16 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-16:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-16:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-17 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.lockupKJList-18 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-18:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-18:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-19 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.lockupKJList-19:first-child {
  color: rgb(119, 119, 119);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-19:last-child {
  margin-top: 6px;
  color: rgb(54, 54, 54);
  font-size: 12px;
  font-weight: 400;
}

.lockupKJList-20:focus {
  outline: 0px;
}

.lockupKJList-20 {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: 0px;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  height: 0px;
  pointer-events: none;
}